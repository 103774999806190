<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>ユーザー名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  v-trim
                                  v-maxlength
                                  maxlength='41'
                                  v-model="searchForm.keyword"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>メールアドレス / ID</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  v-trim
                                  v-model="searchForm.email"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>事務局名／企業名／店舗名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  v-trim
                                  v-model="searchForm.issuer"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>区分</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  v-model="searchForm.kind"
                                >
                                  <option value="">選択してください</option>
                                  <template v-for="(role) in roleList">
                                    <option
                                      v-if="!isRoleOptionHidden(role.value)"
                                      :key="role.value"
                                      :value="role.value"
                                    >
                                      {{ role.label }}
                                    </option>
                                  </template>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>親イベント名</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select
                                  class="form-control form-select-input"
                                  v-model="searchForm.subdomain"
                                >
                                  <option value="">選択してください</option>
                                  <option
                                    v-for="(item, index) in parentEventList"
                                    :key="index"
                                    :value="item.subdomain"
                                  >
                                    {{ item.name }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item">
                    <button
                      class="btn btn-white"
                      @click="resetSearch"
                      type="button"
                    >
                      リセット
                    </button>
                  </li>
                  <li class="listGrid-item">
                    <button class="btn btn-main" type="button" @click="search">
                      検索
                    </button>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay position="center" color="dark">
                  <template v-slot:title>ユーザー数</template>
                  <template v-slot:num>{{ userCount | comma }}</template>
                  <template v-slot:sup>件</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <Table
                    :dataTable="dataTable"
                    :labels="labels"
                    hasButtonColumns
                  >
                  </Table>
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  :listLength="userCountSearch"
                  :searchConditions="searchConditions"
                  modulePath="settingUser/getUsers"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item pos-end">
            <router-link
              class="btn btn-main"
              :to="{ name: 'SettingUserRegisterSelect' }"
              >新規登録</router-link
            >
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
//mixin
import nav from '@/mixins/nav/setting';
import status from '@/mixins/plugin/status';
import search from '@/mixins/plugin/search';
//component
import FormRow from '@/components/FormRow.vue';
import NumDisplay from '@/components/NumDisplay.vue';
import PaginationLog from '@/components/PaginationLog.vue';
import Table from '@/components/Table.vue';
//constant
import { StatusConstants } from '@/constants/status';
import { RoleConstants } from '@/constants/role';
import { addThreeDotEndLine, endline } from '@/helpers/formatData';

export default {
  name: 'SettingUser',
  data: function() {
    return {
      pageName: '設定',
      labels: {
        userName: 'ユーザー名',
        name: '事務局名／企業名／店舗名',
        kind: '区分',
        event: '親イベント名',
      },
      searchConditions: {},
      initialSearchConditions: { hideUserInvalidShop: 1 },
      storeModule: 'settingUser',
      searchFields: ['keyword', 'kind', 'subdomain', 'email', 'issuer'],
    };
  },
  mixins: [nav, status, search],
  components: {
    FormRow,
    NumDisplay,
    PaginationLog,
    Table,
  },
  computed: {
    ...mapGetters({
      userList: 'settingUser/userList',
      parentEventList: 'event/parentEventList',
      userCount: 'settingUser/userCount',
      userCountSearch: 'settingUser/userCountSearch',
      userRole: '',
      hasShopRole: 'auth/hasShopRole',
    }),
    dataTable() {
      return (
        this.userList &&
        this.userList.map((item) => {
          let routeName = '';
          if (item.kind === RoleConstants.ROLE_OFFICE)
            routeName = 'SettingUserEditClient';
          else if (item.kind === RoleConstants.ROLE_STORE)
            routeName = 'SettingUserEditShop';
          else routeName = 'SettingUserEditMaster';
          const roleName = (
            this.roleList.find((role) => role.value === item.kind) || {}
          ).label;
          let eventName = '';
          if(item.kind === RoleConstants.ROLE_GMO) eventName = '-';
          else if (item.kind === RoleConstants.ROLE_OFFICE) {
            if (item.event?.length === 0 ) eventName = '-';
            else if (item.event?.length === 1) eventName = item.event[0]?.name;
            else eventName = item.event[item.event?.length - 1]?.name + '、他';
          }
          else eventName = item.event?.name;
          return {
            userName: endline(item?.nameSei + ' ' + item?.nameMei, 21),
            name: addThreeDotEndLine(item.company ? item.company.name : item.shop?.name || '-'),
            kind: roleName,
            event: eventName,
            linkButtonColumns: [
              {
                content: '編集',
                class: 'btn btn-sm btn-bd-main',
                routeName: routeName,
                params: { id: item.id },
                query: this.$route.query,
              },
            ],
          };
        })
      );
    },
    isRoleOptionHidden() {
      return (value) => {
        return this.$permission.isOffice() && StatusConstants.role.gmo.value === value;
      }
    },
    roleList() {
      if (!this.hasShopRole) {
        const newData = { ...StatusConstants.role };
        delete newData?.role?.store;
        return Object.values(newData)
      }
      return Object.values(StatusConstants.role);
    }
  },
  mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.$store.dispatch('event/getParentEventList', { limit: 9999, hasAggregate: 0 }),
      this.$store.dispatch('settingUser/getUserCount', { hideUserInvalidShop: 1 }),
    ]).finally(() => {
      this.$loading.clear(loading);
    });
  },
};
</script>
